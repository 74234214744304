<template>
  <div class="member-publish">
    <Breadcrumb>
      <BreadcrumbItem to="/user/merchandise">我的商品</BreadcrumbItem>
      <BreadcrumbItem>发布商品</BreadcrumbItem>
    </Breadcrumb>
    <PublishPanel title="发布商品">
      <PublishForm />
    </PublishPanel>
  </div>
</template>

<script>
import PublishPanel from '@/views/member/merchandise/components/PublishPanel'
import PublishForm from '@/views/member/merchandise/components/PublishForm'
// import PageSection from '@/components/CommonComponent/page-section/PageSection.vue'
export default {
  name: 'MerchandisePublish',
  components: { PublishPanel, PublishForm },
  data () {
    return {
    }
  },
  methods: {
  },
  setup () {},
}
</script>

<style lang="less" scoped>
.member-publish {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
/deep/ .ivu-input {
  border: 1px solid #dcdee2 !important;
  padding: 4px 7px !important;
}
.form-area {
  padding: 20px 10%;
}

</style>
