<template>
  <div class="publish-form">
    <div class="form-area">
      <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="150"
      >
        <PageSection>
          <template v-slot:title>商品信息</template>
          <FormItem label="商品分类">
            <Cascader
              v-model="formValidate.categoryTree"
              :data="dataInfo"
              trigger="hover"
            ></Cascader>
          </FormItem>
          <FormItem label="商品名称" prop="selectName">
            <Select v-model="formValidate.selectName">
              <Option v-for="item in nameList" :key="item" :value="item">
                {{ item }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="商品规格" prop="selectFormates">
            <Select v-model="formValidate.selectFormates">
              <Option v-for="item in formatesList" :key="item" :value="item">
                {{ item }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="商品材质" prop="selectMaterials">
            <Select v-model="formValidate.selectMaterials">
              <Option v-for="item in materialsList" :key="item" :value="item">
                {{ item }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="最小起订量" prop="minSaleCount">
            <Input
              v-model="formValidate.minSaleCount"
              type="number"
              placeholder="请输入最小起订量"
            ></Input>
          </FormItem>
          <FormItem label="库存" prop="inventory">
            <Input
              v-model="formValidate.inventory"
              type="number"
              placeholder="请输入库存"
              @on-keyup="inputChange(formValidate.inventory)"
            ></Input>
          </FormItem>
          <FormItem label="仓库" prop="warehouseName">
            <Input
              v-model="formValidate.warehouseName"
              placeholder="请输入仓库"
            ></Input>
          </FormItem>
          <FormItem label="发货地" prop="sendFromCityCode">
            <Input
              v-model="formValidate.sendFromCityCode"
              placeholder="请输入发货地"
            ></Input>
          </FormItem>
          <FormItem label="单价" prop="activePrice">
            <Row class="form-row">
              <Col span="18">
                <Input
                  v-model="formValidate.activePrice"
                  placeholder="请输入单价"
                  type="number"
                ></Input>
              </Col>
              <Col span="6">
                <Select v-model="selectUnits">
                  <Option v-for="item in unitsList" :key="item" :value="item">
                    {{ item }}
                  </Option>
                </Select>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="支付模式" prop="tradeType">
            <RadioGroup v-model="formValidate.tradeType">
              <Radio label="1">全款模式</Radio>
              <Radio label="2" :disabled="isDisabled">保证金模式</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="保证金" prop="distributionType">
            <label>{{ deposit }}%</label>
          </FormItem>
          <FormItem label="实物图片" prop="goodsImg">
            <Upload
              :before-upload="logHandleUpload"
              :on-success="logUploadGoodsSuccess"
              :on-remove="logoUploadRemove"
              accept="image"
              :multiple="false"
              :data="{ path: 'goods' }"
              type="drag"
              action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
            >
              <Button icon="ios-cloud-upload-outline" class="btn-upload">
                点击上传
              </Button>
            </Upload>
            <span>文件大小在6MB以内、支持png，jpeg、gif格式</span>
          </FormItem>

          <FormItem label="商品描述" prop="remark">
            <Input
              v-model="formValidate.remark"
              placeholder="请输入商品描述"
              type="textarea"
              :rows="4"
              class="ivu-input-fix"
            ></Input>
          </FormItem>
          <FormItem label="上传货权凭证" prop="certificateImg">
            <Upload
              :before-upload="logHandleUpload"
              :on-success="logUploadCertificateSuccess"
              :on-remove="logoUploadRemove"
              accept="image"
              :multiple="false"
              :data="{ path: 'goods' }"
              type="drag"
              action="http://file.qltrade.ixiaoguo.com.cn/file/upload"
            >
              <Button icon="ios-cloud-upload-outline" class="btn-upload">
                点击上传
              </Button>
            </Upload>
            <span>文件大小在6MB以内、支持png，jpeg、gif格式</span>
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              class="btn"
              @click="handleSubmit('formValidate')"
            >
              发布
            </Button>
          </FormItem>
        </PageSection>
      </Form>
    </div>
  </div>
</template>
<script>
import PageSection from '@/components/CommonComponent/page-section/PageSection.vue'
import { fileUploadLimit } from '@/utils/request.js'
import { ref } from 'vue'
export default {
  name: 'PublishForm',
  components: { PageSection },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      dataInfo: [], // 商品筛选信息
      // categoryTree: [],
      nameList: [],
      formatesList: [],
      materialsList: [],
      unitsList: [],
      deposit: 0, // 保证金额度
      isDisabled: false,
      selectCategoryId: '', // 一级分类id
      selectSubCategoryId: '', // 下拉框选择的商品分类 二级分类id
      // selectName: '', // 下拉框选择的商品名
      // selectFormates: '', // 下拉框选择的商品规格
      // selectMaterials: '', // 下拉框选择的商品材质
      selectUnits: '', // 下拉框选择的商品单位
      formValidate: {
        activePrice: '', // 售价
        selectCategoryId: '', // 一级分类id
        selectSubCategoryId: '', // 下拉框选择的商品分类 二级分类id
        selectName: '', // 下拉框选择的商品名
        selectFormates: '', // 下拉框选择的商品规格
        selectMaterials: '', // 下拉框选择的商品材质
        selectUnits: '', // 下拉框选择的商品单位
        categoryTree: [],
        tradeType: '',
        inventory: '', // 库存
        sendFromCityCode: '', // 发货地
        warehouseName: '', // 仓库
        minSaleCount: 0, // 最小起订量
        goodsImg: [],
        certificateImg: [],
        remark: '',
      },
      ruleValidate: {
        selectName: [
          {
            required: true,
            message: '商品名称不能为空',
            trigger: 'blur',
          },
        ],
        selectFormates: [
          {
            required: true,
            message: '商品规格不能为空',
            trigger: 'blur',
          },
        ],
        selectMaterials: [
          {
            required: true,
            message: '商品材质不能为空',
            trigger: 'blur',
          },
        ],
        minSaleCount: [
          {
            required: true,
            message: '最小起订量不能为空',
            trigger: 'blur',
          },
        ],
        inventory: [
          {
            required: true,
            message: '库存不能为空',
            trigger: 'blur',
          },
        ],
        warehouseName: [
          {
            required: true,
            message: '仓库不能为空',
            trigger: 'blur',
          },
        ],
        sendFromCityCode: [
          {
            required: true,
            message: '发货地不能为空',
            trigger: 'blur',
          },
        ],
        activePrice: [
          {
            required: true,
            message: '单价不能为空',
            trigger: 'blur',
          },
        ],
        selectUnits: [
          {
            required: true,
            message: '单价不能为空',
            trigger: 'blur',
          },
        ],
        tradeType: [
          {
            required: true,
            message: '支付模式不能为空',
            trigger: 'change',
          },
        ],
        goodsImg: [
          {
            required: true,
            type: 'array',
            min: 1,
            message: '实物图片不能为空',
            trigger: 'change',
          },
          // {
          //   required: true,
          //   message: '实物图片不能为空',
          //   trigger: 'blur',
          // },
        ],
        remark: [
          {
            required: true,
            message: '商品描述不能为空',
            trigger: 'blur',
          },
        ],
        certificateImg: [
          {
            required: true,
            type: 'array',
            min: 1,
            message: '货权凭证不能为空',
            trigger: 'change',
          },
          // {
          //   required: true,
          //   message: '货权凭证不能为空',
          //   trigger: 'blur',
          // },
        ],
      },
    }
  },
  computed: {
    categoryTree () {
      // console.log(
      //   'this.formValidate.categoryTree',
      //   this.formValidate.categoryTree,
      // )
      return this.formValidate.categoryTree
    },
    selectName () {
      // 下拉框选择的商品名
      return this.formValidate.selectName
    },
    selectFormates () {
      // 下拉框选择的商品规格
      return this.formValidate.selectFormates
    },
    selectMaterials () {
      // 下拉框选择的商品材质
      return this.formValidate.selectMaterials
    },
  },

  created () {
    this.init()
  },
  methods: {
    init () {
      this.$store
        .dispatch('CategoryTree')
        .then((response) => {
          this.categoryTreeList = response.result

          response.result.forEach((item) => {
            const children = []
            item.subCategoryList.forEach((i) => {
              children.push({
                value: i.categoryId,
                label: i.categoryName,
              })
            })
            this.dataInfo.push({
              value: item.deposit.categoryId,
              label: item.deposit.categoryName,
              depositValue: item.deposit.depositValue,
              children,
            })
          })
        })
        .catch(() => {})

      this.$store
        .dispatch('Units')
        .then((response) => {
          this.unitsList = response.result
        })
        .catch(() => {})
    },
    inputChange () {
      this.formValidate.inventory = this.formValidate.inventory.replace(
        /^(-)*(\d+)\.(\d\d).*$/,
        '$1$2.$3',
      )
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (!this.selectUnits) {
          this.$Message.error('请选择单位')
          return
        }
        if (valid) {
          // 商品发布
          const postData = ref({
            categoryId: this.formValidate.selectCategoryId,
            subCategoryId: this.formValidate.selectSubCategoryId,
            productName: this.formValidate.selectName,
            // depositAmount:
            //   (parseInt(this.deposit) *
            //     parseInt(this.formValidate.activePrice) *
            //     parseInt(this.formValidate.inventory)) /
            //   100,
            depositAmount: 0, // 更新为后端计算，前端传
            formate: this.formValidate.selectFormates,
            material: this.formValidate.selectMaterials,
            minSaleCount: this.formValidate.minSaleCount,
            warehouseName: this.formValidate.warehouseName,
            sendFromCityCode: this.formValidate.sendFromCityCode,
            activePrice: this.formValidate.activePrice,
            unitName: this.selectUnits,
            tradeType: this.formValidate.tradeType,
            inventory: this.formValidate.inventory,
            img: this.formValidate.goodsImg,
            certificateImg: this.formValidate.certificateImg,
            remark: this.formValidate.remark,
          })
          if (this.isDisabled) {
            // 保证金未0 时
            this.$store
              .dispatch('AddProduct', postData.value)
              .then((response) => {
                if (response.returnCode === '1') {
                  this.$Message['success']({
                    background: true,
                    content: '商品发布成功',
                  })
                  this.$router.push('/user/merchandise')
                } else {
                  this.$Spin.hide()
                  this.$Message['error']({
                    background: true,
                    content: '商品发布失败',
                  })
                }
              })
              .catch(() => {})
          } else {
            this.$Spin.show({
              render: (h) => {
                return h('div', [
                  h(
                    'div',
                    {
                      style: {
                        color: '#d12b23',
                        fontSize: '22px',
                      },
                    },
                    '正在启动银行支付请不要离开页面。否则无法完成支付...',
                  ),
                ])
              },
            })
            this.$store
              .dispatch('AddProduct', postData.value)
              .then((response) => {
                if (response.returnCode === '1') {
                  // this.$Spin.hide()
                  if (response.result.authUrl) {
                    window.open(response.result.authUrl, '_self')
                  }
                } else {
                  this.$Spin.hide()
                  this.$Message['error']({
                    background: true,
                    content: '商品发布失败',
                  })
                }
              })
              .catch(() => {})
          }
        } else {
          this.$Message.error('请填写正确的信息')
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    logHandleUpload (file) {
      // if (this.formValidate.goodsImg) {
      //   this.$Message.error('公司log只能上传一张图片，请先删除之前已上传图片')
      //   return false
      // }
      const fileValide = fileUploadLimit(file, this.fileLimitSize)
      if (fileValide) {
        this.$Message.error(fileValide)
        return false
      }
      return true
    },
    logUploadGoodsSuccess (response, file, fileList) {
      if (response.returnCode === '1') {
        this.formValidate.goodsImg.push(response.result)
        // this.$refs.formValidate.validateField('logo')
      }
    },
    logUploadCertificateSuccess (response, file, fileList) {
      if (response.returnCode === '1') {
        this.formValidate.certificateImg.push(response.result)
        // this.$refs.formValidate.validateField('logo')
      }
    },

    logoUploadRemove () {
      this.formValidate.logo = ''
      this.$refs.formValidate.validateField('logo')
    },
  },
  watch: {
    categoryTree (val) {
      this.formatesList = []
      this.materialsList = []
      this.nameList = []
      this.formValidate.selectName = ''
      this.formValidate.selectFormates = ''
      this.formValidate.selectMaterials = ''
      this.formValidate.selectCategoryId = val[0]
      this.formValidate.selectSubCategoryId = val[1]

      const postData = ref({
        subCategoryId: this.formValidate.selectSubCategoryId,
      })
      this.$store
        .dispatch('ProductNames', postData.value)
        .then((response) => {
          this.nameList = response.result
        })
        .catch(() => {})
      // 获取保证金比例
      this.dataInfo.forEach((item) => {
        if (item.value === this.formValidate.selectCategoryId) {
          if (Number(item.depositValue) === 0) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
          this.deposit = item.depositValue
        }
      })
    },

    selectName (val) {
      this.formValidate.selectName = val
      const postData = ref({
        subCategoryId: this.formValidate.selectSubCategoryId,
        productName: val,
      })
      this.$store
        .dispatch('Formates', postData.value)
        .then((response) => {
          this.formatesList = response.result
        })
        .catch(() => {})
    },

    selectFormates (val) {
      this.formValidate.selectFormates = val
      const postData = ref({
        subCategoryId: this.formValidate.selectSubCategoryId,
        productName: this.formValidate.selectName,
        formate: val,
      })
      this.$store
        .dispatch('Materials', postData.value)
        .then((response) => {
          this.materialsList = response.result
        })
        .catch(() => {})
    },
    selectUnits (val) {
      this.selectUnits = val
    },
  },
}
</script>
<style scoped lang="less">
.publish-form {
  background-color: #fff;
  padding: 0 20px;
  margin-top: 20px;
  // height: 400px;
  // border: 1px solid #dcdfe6;
  .goods-list {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }
}
.btn {
  // display: block;
  float: right;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-upload {
  // display: block;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
</style>
